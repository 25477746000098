import {PiniaPluginContext} from 'pinia';
import {LocalStorage, SessionStorage} from 'quasar';


export const persistSessionStatePlugin = (context: PiniaPluginContext) => {
  const {store} = context;
  const persistedStores = ['persistentSession']; // IDs of stores to persist

  if (persistedStores.includes(store.$id)) {
    // Load initial state from localStorage for the specific store
    const savedState = SessionStorage.getItem(store.$id);
    if (savedState) {
      if (typeof savedState === 'string') {
        store.$patch(JSON.parse(savedState));
      }
    }

    // Watch for changes in the specific store and save them
    store.$subscribe((mutation, state) => {
      SessionStorage.set(store.$id, JSON.stringify(state));
    });
  }
};

export const persistLocalStatePlugin = (context: PiniaPluginContext) => {
  const {store} = context;
  const persistedStores = ['persistentLocal']; // IDs of stores to persist

  if (persistedStores.includes(store.$id)) {
    // Load initial state from localStorage for the specific store
    const savedState = LocalStorage.getItem(store.$id);
    if (savedState) {
      if (typeof savedState === 'string') {
        store.$patch(JSON.parse(savedState));
      }
    }

    // Watch for changes in the specific store and save them
    store.$subscribe((mutation, state) => {
      LocalStorage.set(store.$id, JSON.stringify(state));
    });
  }
};
