import {boot} from 'quasar/wrappers';
import {Cookies, Platform} from 'quasar';
import {useState} from 'vue-gtag-next';
import CookieConsentCmp from 'src/components/qutils/cookieConsent/CookieConsent'
// import LogRocket from 'logrocket'
// import {useMainStore} from 'src/stores/main';

const {cookieConsent, CookieScript} = CookieConsentCmp

export default boot(({app, /* store */}) => {
  // const mainStore = useMainStore(store)

  const matomo = new CookieScript({
    name: 'Matomo',
    label: 'Analytics',
    required: true,
    slug: 'campertrader_consent_matomo',
    description: 'Es werden Seitenaktivitäten erfasst. Mit diesen Daten können wir die Seite immer weiter verbessern.',

    onAccept: () => {
      Cookies.remove('mtm_consent_removed', {path: '/'})

      if (window && window.hasOwnProperty('_paq')) {
        const _paq = (window as any)._paq || []
        _paq.push(['rememberCookieConsentGiven'])
      }
    },

    onDecline: () => {
      if (window && window.hasOwnProperty('_paq')) {
        const _paq = (window as any)._paq || []
        _paq.push(['forgetCookieConsentGiven'])
      }
    }
  } as any)

  const gtag = new CookieScript({
    name: 'Google Analytics',
    label: 'Analytics',
    slug: 'campertrader_consent_ga',
    description: 'Es werden Seitenaktivitäten erfasst. Mit diesen Daten können wir die Seite immer weiter verbessern.',

    onAccept: () => {
      const {isEnabled} = useState();
      if (isEnabled) {
        isEnabled.value = true;
      }
    },

    onDecline: () => {
      const {isEnabled} = useState();
      if (isEnabled) {
        isEnabled.value = false;
      }
    }
  })

  // const logrocket = new CookieScript({
  //   name: 'Log Rocket',
  //   label: 'Analytics',
  //   slug: 'logrocket_analytics',
  //   description: 'Es werden Klick, Scroll und Maus Aktivitäten erfasst. Mit diesen Daten können wir das Design der Seite verbessern.',
  //
  //   onAccept: async () => {
  //     if (process.env.DEV) {
  //       console.debug('LogRocket is disabled in DEV mode.')
  //       return
  //     }
  //     LogRocket.init('sbm0lo/campertrader');
  //     if (mainStore.accountDetail?.uuid) {
  //       LogRocket.identify(mainStore.accountDetail?.uuid);
  //     }
  //   },
  //
  //   onDecline: () => {
  //     return
  //   }
  // })

  const facebookPixel = new CookieScript({
    name: 'Meta Pixel',
    label: 'Analytics',
    slug: 'metapixel_analytics',
    description: 'Es werden Seitenaktivitäten erfasst. Mit diesen Daten können wir die Seite immer weiter verbessern.',

    onAccept: () => {
      function loadFacebookPixel(pixelId) {
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            // eslint-disable-next-line prefer-rest-params,prefer-spread
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        } as any)(window, document, 'script', 'https://connect.facebook.net/de_DE/fbevents.js');

        (window as any).fbq('init', pixelId);
      }

      try {
        loadFacebookPixel(process.env.META_PIXEL_ID)
      } catch (e) {
        console.error(e)
      }
    },
    onDecline: () => {
      return
    }
  })

  if (!process.env.DEV) {
    // don't add this scripts to mobile, because there is no cookie banner
    if (!Platform.is.nativeMobile) {
      // cookieConsent.add(logrocket)
      cookieConsent.add(facebookPixel)
      cookieConsent.add(gtag)
    }
    cookieConsent.add(matomo)

    if (!Platform.is.nativeMobile) {
      cookieConsent.init()
    } else {
      // run scripts on mobile without looking for cookie
      cookieConsent.processScripts()
    }
  }


  app.config.globalProperties.$cookieConsent = cookieConsent
});

export {cookieConsent}
