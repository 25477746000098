export default [
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/flohmarkt/:slug?/:uuid/',
        component: () => import('src/apps/market/pages/ItemDetailPage.vue'),
        name: 'item-detail',
        meta: {hasBackBtn: true, noFooterBar: true},
      },

      {
        path: '/item/create/finish/:uuid/',
        component: () => import('src/apps/market/pages/CreateFinishPage.vue'),
        name: 'create-item-finish',
        meta: {loginRequired: true, noFooter: true, hasBackBtn: false},
      }
    ],
  },
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/item/create/',
        component: () => import('src/apps/market/pages/CreateUpdatePage.vue'),
        name: 'create-item',
        meta: {loginRequired: true, noFooter: true},
      },
      {
        path: '/item/update/:uuid/',
        component: () => import('src/apps/market/pages/CreateUpdatePage.vue'),
        name: 'update-item',
        meta: {loginRequired: true, noFooter: true},
      },
    ]
  }
]
