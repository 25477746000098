import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"z-index":"400"},
  class: "c-header-container"
}
const _hoisted_2 = { class: "flex no-wrap justify-center items-center text-bold text-h4 q-py-sm" }


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogHeader',
  props: {
  closeIcon: {type: Boolean, default: true},
  closePopup: {type: Boolean, default: true},
  separator: {type: Boolean, default: true},
  label: {type: String, default: undefined},
  buttonIcon: {type: String, default: 'fas fa-close'},
},
  emits: ['btnClick'],
  setup(__props) {





return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_card_section, { class: "q-py-xs" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              (__props.closeIcon)
                ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    icon: __props.buttonIcon,
                    round: "",
                    color: "white",
                    "text-color": "dark",
                    "aria-label": "close",
                    style: {"margin-left":"-8px"},
                    unelevated: "",
                    onClick: _cache[0] || (_cache[0] = e => _ctx.$emit('btnClick', e))
                  }, null, 8, ["icon"])), [
                    [_directive_close_popup, __props.closePopup]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(__props.label), 1)
          ])
        ])
      ]),
      _: 3
    }),
    (__props.separator)
      ? (_openBlock(), _createBlock(_component_q_separator, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})