import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, Transition as _Transition, unref as _unref } from "vue"

const _hoisted_1 = { class: "absolute-center overflow-hidden full-width flex column items-center no-wrap" }
const _hoisted_2 = {
  style: {"height":"21px"},
  class: "q-mt-md"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "text-white"
}
const _hoisted_5 = { key: 1 }

import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import {useMainStore} from 'stores/main';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingScreen',
  setup(__props) {


const mainStore = useMainStore()
const showInfo = ref(false)

const dialog = computed({
  get() {
    return mainStore.siteLoading
  },
  set(val: boolean) {
    mainStore.setSiteLoading(val)
  }
})

let timeout
onMounted(() => {
  showInfo.value = false
  timeout = setTimeout(() => {
    showInfo.value = true
  }, 5000)
})

onBeforeUnmount(() => {
  clearTimeout(timeout)
})


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: dialog.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialog).value = $event)),
    "no-shake": "",
    "no-backdrop-dismiss": "",
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_unref(mainStore).setSiteLoading(false)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_q_img, {
            class: "c-logo",
            src: require('assets/campertrader/logo/campertrader_icon_white.svg'),
            alt: "Camper Trader Logo",
            fit: "contain",
            height: "80px",
            loading: "eager",
            "no-spinner": "",
            "no-transition": "",
            width: "80px"
          }, null, 8, ["src"])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-white c-text text-bold q-mt-sm" }, "Lade ...", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_Transition, {
            appear: "",
            "enter-active-class": "animated fadeIn"
          }, {
            default: _withCtx(() => [
              (showInfo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.$q.platform.is.desktop)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "ESC-Taste zum Abbrechen"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                            flat: "",
                            dense: "",
                            "no-caps": "",
                            color: "white"
                          }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode(" Abbrechen ")
                            ])),
                            _: 1
                          })), [
                            [_directive_close_popup]
                          ])
                        ]))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})