import {api} from 'boot/axios';
import {useTracking} from 'src/etc/tracking';

export default {
  getUserChats: function () {
    return api.get('chats/')
  },

  getMessages: function (chatUuid, config: object | undefined = undefined) {
      return api.get(`chats/${chatUuid}/messages/`, config)
  },

  markReadMessage(chatUuid) {
    return api.post(`chats/${chatUuid}/mark_read/`)
  },

  getUnreadMessagesCount: function () {
    return api.get('chats/unread/')
  },

  getOrCreateChat(advertUuid: string | undefined = undefined, itemUuid: string | undefined, participants: Array<string> | undefined = undefined) {
    return api.post('chats/create/', {participants, advert: advertUuid, item: itemUuid})
  },

  getChat: function (chatUuid) {
    return api.get(`chats/${chatUuid}/`)
  },

  createMessage(chatUuid, data) {
    return api.post(`chats/${chatUuid}/messages/`, data).then(resp => {
      const tracker = useTracking()
      tracker.trackAdvertInteractionMessage()
      return resp
    })
  },

  deactivateChat(chatUuid) {
    return api.post(`chats/${chatUuid}/deactivate/`)
  }
}
