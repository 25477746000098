import {Cookies} from 'quasar';
import {api} from 'boot/axios';
import {useMainStore} from 'stores/main';
import {useSubscriptionStore} from 'src/apps/subscription/store';


let
  CapacitorGeolocation,
  CapacitorPreferences,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate


export default (async ({ssrContext, router, store}) => {
  const mainStore = useMainStore(store)
  const subStore = useSubscriptionStore(store)

  const {Capacitor} = await import('@capacitor/core')
  const {LocalNotifications} = await import('@capacitor/local-notifications')
  const {Device} = await import('@capacitor/device')
  const {Camera} = await import('@capacitor/camera')
  const {Geolocation} = await import('@capacitor/geolocation')
  const {Preferences} = await import('@capacitor/preferences')
  const {PushNotifications} = await import('@capacitor/push-notifications')
  const {Share} = await import('@capacitor/share')
  const {Browser} = await import('@capacitor/browser')
  const {App} = await import('@capacitor/app')
  const {Filesystem, Directory} = await import('@capacitor/filesystem')
  const {Network} = await import('@capacitor/network')
  const {AppTrackingTransparency} = await import('capacitor-ios-app-tracking')
  const {LiveUpdate} = await import('@capawesome/capacitor-live-update')

  CapacitorGeolocation = Geolocation
  CapacitorPreferences = Preferences
  CapacitorPushNotifications = PushNotifications
  CapacitorShare = Share
  CapacitorCore = Capacitor
  CapacitorDevice = Device
  CapacitorCamera = Camera
  CapacitorFilesystem = Filesystem
  CapacitorDirectory = Directory
  CapacitorBrowser = Browser
  CapacitorApp = App
  CapacitorLocalNotifications = LocalNotifications
  CapacitorNetwork = Network
  CapacitorTrackingTransparency = AppTrackingTransparency
  CapacitorLiveUpdate = LiveUpdate

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  // set token if in Preferences
  try {
    const {value} = await Preferences.get({key: 'token'})
    // check if value is empty string or set
    if (value) {
      cookies.set('token', value || '', {
        path: '/',
        secure: !process.env.DEV,
        sameSite: 'Strict',
      })
    }
  } catch (err) {
    console.error(err)
  }

  const addListeners = async () => {
    await LocalNotifications.addListener('localNotificationActionPerformed', async (/*notification*/) => {
      console.log('localNotificationActionPerformed event')
    })

    await PushNotifications.addListener('registration', async (token) => {
      console.debug('Registration token: ', token.value);
      const info = await Device.getInfo();
      const id = await Device.getId();
      const data = {
        name: info.name,
        registration_id: token.value.toString(),
        device_id: id.identifier,
        active: true,
        type: Capacitor.getPlatform(),
      }
      await api.post('devices/', data).then(() => {
        console.debug('Notifications granted')
      }).catch((err) => {
        console.error('Error:', err.message)
      })
    })

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', async notification => {
      console.log('Push notification received: ', notification);

      const data = notification.data;
      console.log(`Data received: ${JSON.stringify(data)}`);

      const notificationId = Math.floor(Math.random() * 100000);

      // Schedule a local notification
      await LocalNotifications.schedule({
        notifications: [
          {
            title: data.title,
            body: data.body,
            id: notificationId,
            extra: {
              type: data.type,
              chat_uuid: data.chat_uuid,
              link: data.link
            }
          }
        ]
      });
    });


    await PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
      console.log('Push notification action performed', notification.toString());
      const data = notification.notification.data;

      if (!mainStore.accountDetail?.uuid) {
        await mainStore.fetchAccountDetails()
      }
      if (data.type === 'new_message') {
        if (data.to_user !== mainStore.accountDetail?.active_account?.uuid) {
          if (mainStore.accountDetail?.uuid === data.to_user) {
            await mainStore.setActiveAccount(undefined)
            console.log(`Switched to user: ${mainStore.accountDetail?.public_name}`)
          } else {
            const business: object | undefined = mainStore.accountDetail?.businesses?.find(b => b['uuid'] === data.to_user)
            if (business) {
              await mainStore.setActiveAccount(business['slug'])
              console.log(`Switched to business: ${business['slug']}`)
            } else {
              console.log('Could not find business')
              router.push({name: 'messenger'})
              return
            }
          }
        }
        await router.push({name: 'messenger-chat', params: {chatUuid: data.chat_uuid}});
      }

    });

    await App.addListener('resume', function () {
      if (mainStore.isLoggedIn) {
        mainStore.fetchUnreadMessagesCount()
        mainStore.fetchAccountDetails()
        subStore.fetchAccountQuota()
        subStore.fetchAccountSubscription()
      }
      console.debug('App resumed')
    })
  }

  try {
    await addListeners()
  } catch (e) {
    console.warn('could not run push notification listeners\n', e)
  }
})

export {
  CapacitorPreferences,
  CapacitorGeolocation,
  CapacitorPushNotifications,
  CapacitorShare,
  CapacitorCore,
  CapacitorDevice,
  CapacitorCamera,
  CapacitorFilesystem,
  CapacitorDirectory,
  CapacitorBrowser,
  CapacitorApp,
  CapacitorLocalNotifications,
  CapacitorNetwork,
  CapacitorTrackingTransparency,
  CapacitorLiveUpdate
}
