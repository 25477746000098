import {api} from 'boot/axios';

export default {
  getFAQs: function () {
    return api.get('faq/')
  },

  getConfig: function () {
    return api.get('config/')
  },

  fetchContactForm: function () {
    return api.options('contact-form/')
  },

  createContactMessage: function (data) {
    return api.post('contact-form/', data)
  },

  getCompanyOptions: function () {
    return api.options('user/company/edit/')
  },

  createUpdateCompany: function (data) {
    return api.post('user/company/edit/', data)
  },

  getCompany: function () {
    return api.get('user/company/edit/')
  },

  deleteCompany: function () {
    return api.delete('user/company/edit/')
  },

  setActiveBusinessAccount: function (slug: string | null) {
    return api.post('user/set-active/', {slug}).catch(error => {
      console.error(error)
      return error
    })
  },

  fetchWidgetUserList: function () {
    return api.get('widgets/users/')
  },

  fetchStatsWidgetValues: function () {
    return api.get('widgets/stats/')
  },

  fetchCities() {
    return api.get('cities/')
  },

  checkAddress(address: object) {
    return api.post('address-check/', address)
  },

  fetchTextToFeatures(text: string) {
    return api.post('utils/text_to_features/', {text: text})
  },

  fetchGoogleReviews() {
    return api.get('etc/google-reviews/')
  },

  uploadTmpImage(data, options: any = undefined) {
    return api.post('tmp/images/', data, options)
  }
}
