import {api} from 'boot/axios';

export default {

  fetchItems: function (params: object | undefined = undefined, cancelToken: any = undefined) {
    return api.get('marketplace/items/', {params, cancelToken})
  },

  toggleUserFavorite: function (itemUuid: string) {
    return api.post(`marketplace/items/${itemUuid}/toggle-favorite/`)
  },

  fetchItem: function (uuid: string) {
    return api.get(`marketplace/items/${uuid}/`)
  },

  fetchItemOptions: function () {
    return api.options('marketplace/items/')
  },

  createItem(data) {
    return api.post('marketplace/items/', data)
  },

  updateItem(uuid, data) {
    return api.put(`marketplace/items/${uuid}/`, data)
  },

  fetchCategories() {
    return api.get('marketplace/categories/')
  },

  fetchCategory(categoryId) {
    return api.get(`marketplace/categories/${categoryId}/`)
  },

  changeItemStatus(uuid, status: 'active' | 'inactive') {
    return api.patch(`marketplace/items/${uuid}/set_status/`, {status})
  },

  // Favorites
  getUserFavorites: function () {
    return api.get('marketplace/favorites/',)
  },

  removeFavorite: function (favoriteUuid) {
    return api.delete(`marketplace/favorites/${favoriteUuid}/`,)
  },

  getOwnItems: function (page = 1) {
    return api.get('marketplace/own/', {params: {page}})
  },

  getItemOptions: function () {
    return api.options('marketplace/items/')
  },

  getReasonChoices: function () {
    return api.get('marketplace/deletion-reasons/')
  },

  deleteItem: function (uuid, deleted_reason = undefined) {
    return api.delete(`marketplace/items/${uuid}/`, {params: {deleted_reason}})
  },

  getUserLastViewed: function (config = undefined) {
    return api.get('marketplace/items/last-viewed/', config)
  },

  setItemImagePostion(itemUuid, imageUuid, position) {
    return api.post(`marketplace/items/${itemUuid}/images/${imageUuid}/set_postion/`, {position})
  },
}
