import {CapacitorGeolocation, CapacitorShare} from 'boot/inject-capacitor'
import Filter from 'src/etc/qutils/filter'
import ShareModal from 'components/ShareModal.vue'
import confetti from 'canvas-confetti'
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static'
import CreateChooseDialog from 'src/apps/core/dialogs/CreateChooseDialog.vue'
import LoginSignupDialog from 'src/apps/auth/dialogs/LoginSignupDialog.vue'

export function axiosConsoleError(error: any) {
  console.error('ERROR Fetching: ', error.config?.url, 'Code:', error.response?.status ?? error.code)
}


interface LocationResult {
  latitude: number
  longitude: number
  timestamp?: number
}


export async function locate($q: any): Promise<LocationResult> {
  if ($q.platform.is.capacitor) {
    try {
      const position = await CapacitorGeolocation.getCurrentPosition()
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        timestamp: position.timestamp,
      }
    } catch (error) {
      console.error('Capacitor Geolocation error:', error)
      throw new Error('Standort konnte nicht abgefragt werden.')
    }
  } else if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true, // Use high accuracy
        timeout: 10000, // Maximum time before timeout
        maximumAge: 0, // Accept no cached location
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            timestamp: position.timestamp,
          })
        },
        (err) => {
          console.error('Geolocation error:', err.message)
          reject(new Error('Standort konnte nicht abgefragt werden.'))
        },
        options
      )
    })
  } else {
    throw new Error('Standortabfrage wird nicht unterstützt.')
  }
}


export function getAdvertRate(price: number) {
  const c = 0.01428476
  const rate = Math.ceil(price * c)
  return Filter.currency({
    value: rate, minimumFractionDigits: 0, maximumFractionDigits: 0
  })
}


interface shareOptionsModel {
  title: string
  text: string
  url: string
  dialogTitle?: string
  mtmSource?: string
}


export async function share($q, shareOptions: shareOptionsModel) {
  const opts = {
    title: shareOptions.title,
    text: shareOptions.text,
    url: shareOptions.url,
    dialogTitle: shareOptions.dialogTitle,
  }

  if ($q.platform.is.nativeMobile) {
    const {value} = await CapacitorShare.canShare()

    if (value) {
      await CapacitorShare.share(opts)
      return
    } else {
      console.error('Sharing not supported')
    }
  }

  // if not native or sharing image failed, show the share modal
  $q.dialog({
    component: ShareModal,

    // props forwarded to your custom component
    componentProps: {
      url: shareOptions.url,
      text: shareOptions.text,
      mtmSource: shareOptions.mtmSource,
    },
  })
}


export function openCreateChooserDialog($q, mainStore, componentProps = undefined, options = {}) {

  if (!mainStore.isLoggedIn) {
    return $q.dialog({
      component: LoginSignupDialog
    })
  }

  return $q.dialog({
    component: CreateChooseDialog,
    componentProps,
    ...options
  })
}


export function sprayIt($q) {
  confetti({
    particleCount: 200,
    spread: 90,
    angle: 40,
    startVelocity: $q.screen.gt.md ? 85 : 50,
    origin: {
      y: 0.5,
      x: -0.1,
    }
  })
  confetti({
    particleCount: 200,
    spread: 90,
    angle: 130,
    startVelocity: $q.screen.gt.md ? 85 : 50,
    zIndex: 10,
    origin: {
      y: 0.5,
      x: 1.1,
    }
  })
}


export function goToApp($q) {
  let url = ''
  if ($q.platform.is.ios || $q.platform.is.mac || $q.platform.is.safari) {
    // is iOS
    url = APPLE_STORE_URL
    window.open(url, '_blank')
  } else if ($q.platform.is.android) {
    // is Android
    url = GOOGLE_PLAY_URL
    window.open(url, '_blank')
  } else {
    const playStoreImg = require('src/assets/storeBtns/google-play-badge_200.png')
    const appStoreImg = require('src/assets/storeBtns/apple.svg')
    $q.dialog({
      title: '<div class="text-center">Jetzt im Store herunterladen</div>',
      html: true,
      message: `
      <div class="q-mt-md">
        <div class="flex items-center justify-center" style="gap: 8px">
          <a href="${GOOGLE_PLAY_URL}" target="_blank" style="text-decoration: none">
            <img
              alt="Download Google Play Store" height="45px"
              src="${playStoreImg}"/>
          </a>
          <a href="${APPLE_STORE_URL}" target="_blank" style="text-decoration: none">
            <img alt="Download Apple Store" height="45px" src="${appStoreImg}"/>
          </a>
        </div>
      </div>
      `,
      cancel: false,
      ok: true
    })
    return
  }

}


export function findElementById(arr, id) {
  for (const element of arr) {
    if (element.id === id) {
      return element
    }
    if (element.children && element.children.length > 0) {
      const result = findElementById(element.children, id)
      if (result) {
        return result
      }
    }
  }
  return null
}


export function toggleList(value: any, list: Array<any>) {
  if (!Array.isArray(list)) throw new Error('list must be an array, got ' + typeof list)
  if (list.includes(value)) {
    const i = list.indexOf(value)
    list.splice(i, 1)
  } else {
    list.push(value)
  }
}
