import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

import DialogHeader from 'src/apps/core/components/DialogHeader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDialogContent',
  props: {
  closeIcon: {default: true},
  closePopup: {default: true},
  headerLabel: {type: String},
  headerButtonIcon: {type: String},
  contentClasses: {type: String},
},
  emits: ['headerBtnClick'],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "q-dialog-plugin no-scroll flex column no-wrap" }, {
    default: _withCtx(() => [
      _createVNode(DialogHeader, {
        label: __props.headerLabel,
        "button-icon": __props.headerButtonIcon,
        "close-popup": __props.closePopup,
        "close-icon": __props.closeIcon,
        onBtnClick: _cache[0] || (_cache[0] = e => _ctx.$emit('headerBtnClick', e))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _: 3
      }, 8, ["label", "button-icon", "close-popup", "close-icon"]),
      _createVNode(_component_q_card_section, {
        class: _normalizeClass(["scroll-y", __props.contentClasses]),
        style: {"flex":"1"}
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class"]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_section, { class: "col-shrink" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "footer")
              ]),
              _: 3
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})