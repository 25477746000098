<template>
  <q-dialog
    ref="dialogRef"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="onDialogHide"
  >
    <base-dialog-content header-label="Inserieren" style="width: clamp(80vw, 600px, 600px)">
      <div class="row q-col-gutter-md">
        <div class="col-12">
          <div class="text-h4">Was würdest du gerne inserieren?</div>
        </div>

        <div class="col-12 col-sm-6">
          <q-btn
            unelevated color="dark" no-caps text-color="white" class="q-pa-md full-width" rounded
            @click="$router.push({name: 'createAdvert'})">
            <div class="flex no-wrap text-left items-center justify-center" style="gap: 12px">
              <div class="q-mb-xs">
                <q-icon name="fas fa-car" size="lg"></q-icon>
              </div>
              <div>
                <div class="font-inter-bold text-h4 q-mb-sm">
                  Fahrzeug
                </div>
                <div class="text-xsmall" style="font-weight: normal;line-height: 0.8rem">
                  Campervan, Wohnmobil und andere Camper
                </div>
              </div>
            </div>
          </q-btn>
        </div>

        <div class="col-12 col-sm-6">
          <q-btn
            unelevated
            color="dark"
            no-caps text-color="white"
            class="q-pa-md full-width"
            rounded
            disable
            @click="$router.push({name: 'create-item'})"
          >
            <div class="flex no-wrap text-left items-center justify-center" style="gap: 12px">
              <div class="q-mb-xs">
                <q-icon name="fas fa-tag" size="lg"></q-icon>
              </div>
              <div class="q-mb-sm">
                <div class="text-bold text-italic">
                  <div>Bald verfügbar!</div>
                </div>
                <div class="font-inter-bold text-h4 q-mb-sm">
                  Flohmarkt
                </div>
                <div class="text-xsmall" style="font-weight: normal;line-height: 0.8rem">
                  Campingzubehör, Gesuche, Tauschen, etc.
                </div>
              </div>
            </div>
          </q-btn>
        </div>

      </div>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';

defineProps({})

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogHide} = useDialogPluginComponent()
</script>
