import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory,} from 'vue-router';
import routes from './routes';
import routesApp from './routes-app';
import {useMainStore} from 'stores/main';
import {Cookies, Platform} from 'quasar';
import {SearchType} from 'src/apps/search/types';
import {useSearchStore} from 'src/apps/search/store';
import {useSearchTypeCategories} from 'src/apps/search/categories';
import {api} from 'boot/axios';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default ({store, ssrContext}) => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const platform = process.env.SERVER
    ? Platform.parseSSR(ssrContext)
    : Platform // otherwise we're on client

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  let _routes, isForceApp: boolean
  try {
    isForceApp = !!process.env.FORCE_APP
  } catch (e) {
    isForceApp = false
  }
  if (isForceApp || platform.is.capacitor || platform.is.cordova) {
    _routes = routesApp
  } else {
    _routes = routes
  }

  // Check if quasar app is capacitor here

  const Router = createRouter({
    scrollBehavior: (to, from, savedPosition) => {
      return savedPosition || {left: 0, top: 0}
    },
    routes: _routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  function initSearch(route, store) {
    if (!Object.keys(route.query).length) return

    const searchStore = useSearchStore(store)

    const searchType = route.query.t?.toString()
    if (searchType) {
      searchStore.updateSearchType(searchType as SearchType)
    }

    // set location
    const loc = route.query.l?.toString()
    const radius = route.query.r?.toString()
    if (!!loc) {
      api.post('address-location/', {address: loc}).then(resp => {
        searchStore.setSearchLocation({
          address: resp.data.raw.address,
          latLng: [resp.data.latitude, resp.data.longitude],
          radius: radius ? Number.parseInt(radius) : 800
        })
      }).catch(err => {
        console.error(err)
      })
    }

    // set category
    const cat = route.query.cat?.toString()

    const {getSearchTypeCategories} = useSearchTypeCategories()

    const _searchType = searchStore.searchType
    const category = getSearchTypeCategories(_searchType)
    if (!!cat && category?.length && category.filter(obj => obj.name === cat)) {
      const _cat = category.find(obj => obj.name === cat)
      searchStore.setActiveCategory(_cat)
    }

    // set search query
    const s = route.query.s?.toString()
    if (!!s) {
      searchStore.filterData.s = s
    }
  }

  Router.beforeEach((to, from, next) => {
    const mainStore = useMainStore(store)
    mainStore.siteLoading = false

    if (mainStore.isFirstLoad && to.name === 'search' && to.query.t) {
      initSearch(to, store)
    }

    // set cookie if user wants to see start page
    if (to.name === 'home' && !mainStore.isFirstLoad) {
      cookies.set('navigatedToStart', 'true', {expires: '1d'})
    }

    if (!to.meta?.noSiteLoading) {
      mainStore.setSiteLoading(true, 400)
    }

    if (to.meta?.loginRequired && (to.name !== 'login' && !mainStore.isLoggedIn && !cookies.has('token'))) {
      next({name: 'login', query: {n: to.fullPath, required: 'yes'}})
    } else next()
  })

  Router.afterEach((/*to, from*/) => {
    const mainStore = useMainStore(store)
    mainStore.setSiteLoading(false)

    if (mainStore.isFirstLoad === true && !process.env.SERVER) {
      mainStore.isFirstLoad = false
    }
  })


  return Router;
}
