<template>
  <div class="loading-screen">
    <div style="width: 100%; position:absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);">
      <div style="display: flex; justify-items: center; align-items: center; flex-direction: column">
        <img
          class="loading-screen__image"
          src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: $$$/GeneralStr/196=Adobe Illustrator 27.6.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 759.6 714' style='enable-background:new 0 0 759.6 714;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2300CFC4;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M625,255.4c4.7,4.3,8.5,9.2,11.3,14.7c33.5,67.5-8.2,100.6,4.3,218.1c5.6,53.1,21.9,86.5,44.5,106.5 c46.9-62.2,74.7-139.5,74.7-223.4c0-97.8-37.9-186.8-99.7-253.1L625,255.4L625,255.4L625,255.4z'/%3E%3Cpath class='st0' d='M220.1,702.3c172.6,45.8,236.9-50.1,247.7-214.2c7.6-114.8-39.6-153.4-8.1-218.1c2.3-4.8,5.7-9.2,9.9-13.1 l-42.7-142.8c-2.8-0.3-5.6-0.8-8.3-1.7c-22.1-6.9-34.4-30.5-27.5-52.5c6.9-22.1,30.5-34.4,52.5-27.5c22.1,6.9,34.4,30.5,27.5,52.5 c-3,9.7-9.3,17.5-17.2,22.6L494,241.8c30.9-12.7,74.4-13.7,106.1-1.4L637,95.6C571.2,36.2,484,0,388.3,0C183.2,0,17,166.3,17,371.4 c0,84,27.9,161.4,74.9,223.6c0,0,0,0,0,0C34.6,615.4,13.7,651,0,714c82.2-26.4,136.3-34.6,219.6-11.8l0-0.1 C219.7,702.2,219.9,702.3,220.1,702.3L220.1,702.3L220.1,702.3z'/%3E%3C/g%3E%3Ccircle class='st0' cx='673.6' cy='41.9' r='41.9'/%3E%3C/svg%3E%0A"
          style="width: clamp(100px, 30vw, 150px);"
        />
        <div style="width: clamp(100px, 30vw, 250px); padding: 1.2rem 0">
          <div class="progress-bar">
            <div class="progress-bar-value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
@keyframes organicPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

.loading-screen {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.loading-screen__image {
  animation: organicPulse 1.3s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.progress-bar {
  height: 4px;
  background-color: lightgray;
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #00cfc4;
  animation: indeterminateAnimation 1.3s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.2);
  }
  80% {
    transform: translateX(90%) scaleX(0.5);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
