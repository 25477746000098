import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center q-mb-xl" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "row q-col-gutter-md" }
const _hoisted_4 = {
  key: 0,
  class: "col-12"
}
const _hoisted_5 = {
  key: 1,
  class: "col-12"
}
const _hoisted_6 = { class: "q-mb-md" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "flex column text-center q-gutter-md" }

import {ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useMainStore} from 'stores/main';
import {api} from 'boot/axios';
import {getAccountPageParams, registerDevice} from 'src/etc/helper';
import SocialLogin from 'src/apps/auth/components/SocialLogin.vue';
import {useQuasar} from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  props: ['next', 'showRequired'],
  setup(__props) {


const email = ref();
const password = ref();
const errors = ref({});
const router = useRouter();
const route = useRoute();
const mainStore = useMainStore();
const showPw = ref(false)
const $q = useQuasar()

const props = __props

const onSubmit = async function () {
  errors.value = {}
  mainStore.setSiteLoading(true)
  api.post('auth/login/', {
    email: email.value, password: password.value
  }).then(resp => {
    const token = resp.data.key
    mainStore.login(token).then(() => {
      try {
        registerDevice()
        // TODO: register android device
      } catch (err) {
        console.error(err)
      }

      if (!$q.platform.is.nativeMobile) {
        try {
          const _paq: any = window._paq ?? []
          _paq.push(['setUserId', mainStore.accountDetail?.uuid]);
        } catch (err) {
          console.error(err)
        }
      }

      const redirect = route.query?.n || props.next
      if (redirect) {
        const uniqueKey = new Date().getTime();
        router.replace({path: redirect.toString(), query: {refreshKey: uniqueKey.toString()}})
      } else {
        let params = getAccountPageParams()
        router.replace(params)
      }
    })
  }).catch(err => {
    if (err.response) {
      errors.value = err.response.data
    }
    mainStore.setSiteLoading(false)
  })
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, {
    class: "flex flex-center",
    onSubmit: onSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, {
            style: {"text-decoration":"unset"},
            to: "/"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                alt: "Camper Trader logo",
                width: "200px",
                src: require('src/assets/logos/campertrader_logo_ocean_w_text.svg')
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (errors.value?.non_field_errors)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors.value.non_field_errors, (err) => {
                return (_openBlock(), _createBlock(_component_q_banner, {
                  key: err,
                  class: "bg-negative text-white",
                  rounded: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(err), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.$route.query.required || __props.showRequired)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_q_banner, {
                class: "bg-warning text-center",
                rounded: ""
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "text-bold" }, " Um fortzufahren, erstelle einen Account oder melde dich an. ", -1)
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_q_input, {
          modelValue: email.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
          error: !!errors.value.email || undefined,
          "error-message": errors.value.email?.join(' '),
          autocomplete: "email",
          class: "col-12",
          label: "E-Mail",
          name: "email",
          outlined: "",
          required: "",
          type: "email"
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "fas fa-envelope" })
          ]),
          _: 1
        }, 8, ["modelValue", "error", "error-message"]),
        _createVNode(_component_q_input, {
          modelValue: password.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((password).value = $event)),
          error: !!errors.value.password || undefined,
          "error-message": errors.value.password?.join(' '),
          class: "col-12",
          label: "Passwort",
          name: "password",
          autocomplete: "current-password",
          outlined: "",
          type: !showPw.value ? 'password' : 'text'
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "fas fa-key" })
          ]),
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: !showPw.value ? 'visibility_off' : 'visibility',
              class: "cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (showPw.value = !showPw.value))
            }, null, 8, ["name"])
          ]),
          _: 1
        }, 8, ["modelValue", "error", "error-message", "type"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            class: "q-py-md",
            to: {name: 'reset'},
            "no-caps": "",
            flat: ""
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Password vergessen?")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_btn, {
                style: {"padding":"12px 0"},
                rounded: "",
                "no-caps": "",
                type: "submit",
                color: "tertiary",
                class: "q-mb-sm"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Einloggen ")
                ])),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(SocialLogin),
              _createVNode(_component_q_btn, {
                rounded: "",
                "no-caps": "",
                to: {name: 'signup-private'},
                style: {"padding":"12px 0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "mail" }),
                  _cache[6] || (_cache[6] = _createTextVNode("  Mit E-Mail registrieren "))
                ]),
                _: 1
              }),
              _cache[8] || (_cache[8] = _createElementVNode("div", null, [
                _createTextVNode("Willst du Camper Trader "),
                _createElementVNode("a", {
                  target: "_blank",
                  href: "/haendler-werden"
                }, "gewerblich"),
                _createTextVNode(" nutzen? ")
              ], -1)),
              _createVNode(_component_q_btn, {
                style: {"padding":"12px 0"},
                "no-caps": "",
                rounded: "",
                color: "grey-7",
                to: {name: 'signup-commercial'},
                class: "q-py-sm"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Gewerblichen Nutzer erstellen ")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})